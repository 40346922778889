<template>
  <template v-if="multiDisplay">
    <div class="bom-validation-results-content__list-multi">
      <mi-list-item-label class="q-py-sm" header>
        <span
          class="bom-validation-results-content__item--header
              text-body2 text-uppercase text-weight-bold"
        >
          {{ title }}
        </span>
      </mi-list-item-label>
      <mi-list-item class="bom-validation-results-content-item">
        <mi-list-item-section class="text-weight-light">
          {{ content }}
        </mi-list-item-section>
      </mi-list-item>
    </div>
  </template>

  <template v-else>
    <div class="bom-validation-results-content__list-simple">
      <mi-list-item-label class="q-py-sm" header>
        <template v-if="emphasis">
          <span
            class="bom-validation-results-content__item--header
              text-body2 text-uppercase text-weight-bold"
          >
            {{ content }}
          </span>
        </template>
        <template v-else>
          <span class="bom-validation-results-content__item--text">
            {{ content }}
          </span>
        </template>
      </mi-list-item-label>
    </div>
  </template>
</template>

<script>
  export default {
    name: 'BomValidationsResults',
    props: {
      multiDisplay: {
        type: Boolean,
        required: false,
        default: false
      },
      emphasis: {
        type: Boolean,
        required: false,
        default: false
      },
      title: {
        type: String,
        required: false,
        default: ' '
      },
      content: {
        type: String,
        required: true,
        default: ' '
      }
    }
  }
</script>

<style lang="scss" scoped>
  .bom-validation-results-content {
    color: $mi-anthracite-800;

    &__list-multi {
      list-style-type: none;
      display: grid;
      gap: 10px;
      grid-template-columns: 18% 82%;
    }

    &__list-simple {
      list-style-type: none;
      color: $mi-anthracite-800;
    }

    &__item {
      &--header {
        font-size: 18px;
        font-family: "MAN Europe Condensed", sans-serif;
        font-weight: 700;
        color: $mi-anthracite-800;
      }

      &--text {
        font-size: 14px;
        font-family: "MAN Europe", sans-serif;
        color: $mi-anthracite-800;
      }
    }
  }

  .bom-validation-results-content-item.mi-list-item {
    min-height: auto;
  }

  .bom-validation-results-content-item__title {
    min-width: 100px;
  }
</style>

<template>
  <div class="summary-details-validations">
    <span v-if="!!getSummaryData.length" class="summary-details-validations__title">Details</span>
    <div class="summary-details-validations__details">
      <template v-for="(summaryDetail) in getSummaryData" :key="summaryDetail">
        <template v-for="(row) in summaryDetail" :key="row">
          <div class="summary-details-validations__details--detail">
            <span class="text-uppercase">{{ row.key }}</span>
            <span>{{ row.value }}</span>
          </div>
        </template>
        <div class="summary-details-validations__details--separator"></div>
      </template>
    </div>
  </div>
</template>

<script>

  import { createNamespacedHelpers } from 'vuex'

  const { mapGetters } = createNamespacedHelpers('rule-validation')

  export default {
    name: 'OnDemandSummaryDetailsValidations',
    computed: {
      ...mapGetters(['getSummaryData'])
    }
  }
</script>

<style lang="scss">
  .summary-details-validations {
    width: 100%;
    height: 100%;
    padding: 7%;
    color: $mi-anthracite-800;
    font-family: $mi-typography-font-family-condensed;
    font-weight: 700;

    &__title {
      font-size: 16px;
    }

    &__details {
      margin-top: 24px;

      &--detail {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-bottom: 8px;

        label {
          width: 50%;
          margin: 0 0 3%;
          font-size: 12px;
          text-align: left;
          justify-content: center;
          align-items: center;
        }

        label:first-child {
          margin-right: 5%;
        }

        label:last-child {
          margin-left: 5%;
          font-family: $mi-typography-font-family;
          font-weight: 400;
        }
      }

      &--separator {
        width: 100%;
        margin: 8px 0 16px;
        height: 0;
        border: 1px solid $mi-anthracite-50;
        display: inline-block;
      }

      &--separator:last-child {
        display: none;
      }
    }
  }
</style>
